export const PADDING = {
  XS: "10px",
  SM: "10px",
  MD: "10px",
  LG: "20px"
};
export const SCREEN_WIDTH = window.innerWidth;
export const CUSTOM_FONT = "Montserrat";
export const CUSTOM_COLOUR = "#494443";
export const CUSTOM_DRIVER_COLOUR = "#494443";
export const COMPANY_NAME = "iraite";

export const BUTTON = {
  FONT_SIZE: "17px",
  PADDING: "0"
};
export const PHONE_REGEX = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const MAX_CHARACTERS = 10;
export const CURRENCY = "$";
export const MARGIN = "10px";
