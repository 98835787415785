import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Fab, CardMedia, Icon } from "@material-ui/core";
import { ManageTripStyles } from "../../../styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fade from "@material-ui/core/Fade";
import UserLayout from "../common/UserLayout";
import Activetrips from "./Activetrips";
import CustomDialog from "../../../custom/CustomDialog";
import CustomLoader from "../../../custom/CustomLoader";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import NoReq from "../../driver/common/NoReq";
import ConfirmBooking from "../userbooking/ConfirmBooking";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import { Redirect } from "react-router-dom";

import EventBus from "eventing-bus";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "2%" }}
      >
        {props.children}
      </div>
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class Usertrips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      holderData: [],
      value: 0,
      isSpecificDialog: false,
      bookingId: "",
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      showSnackbar: false,
      pastTripsData: [],
      logOutNow: false
    };
  }

  redirectToDashboard = () => {
    if (this.state.logOutNow) {
      return (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      );
    }
  };

  componentDidMount() {
    this.excuteApi(0);
  }

  showLoader() {
    return <CustomLoader showLoader={this.state.isLoading} />;
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  excuteApi = value => {
    if (value === 0) {
      this.setState({ isLoading: true });
      ApiManager.callPostApi(
        "Usertrips",
        "active api",
        ApiEndPoints.APPORIO_TAXI_APIS.USER_ACTIVE_TRIP_API
      )
        .then(res => {
          if (res.result === "1") {
            this.setState({ holderData: res.data, isLoading: false }, () =>
              console.log("###" + JSON.stringify(this.state.holderData))
            );
          } else {
            this.setState({
              isLoading: false,
              showSnackbar: true,
              snackbarVariant: "success",
              //snakBarMessage: "Error while fetching User trips: " + res.message,
              snakBarMessage: "" + res.message,
              isLoading: false
            });
          }
        })
        .catch(err => alert(JSON.stringify("error" + err)));
    } else {
      this.setState({ isLoading: true });
      ApiManager.callPostApi(
        "Usertrips",
        "past api",
        ApiEndPoints.APPORIO_TAXI_APIS.USER_PAST_TRIP
      )
        .then(res => {
          if (res.result === "1") {
            this.setState({ pastTripsData: res.data, isLoading: false });
          } else {
            this.setState({
              showSnackbar: true,
              snackbarVariant: "success",
              snakBarMessage: "" + res.message,
              isLoading: false
            });
          }
        })
        .catch(err => alert(JSON.stringify("error" + err)));
    }
  };

  handleChange = (event, value) => {
    this.setState({ value }, () => this.excuteApi(value));
  };

  getLogoutResponse(result) {
    EventBus.publish("KILL_HOME", "1");

    console.log("~~~~@~~~~", result);
    if (result) {
      console.log("@1@1@1@ ::" + result);
      SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
      this.setState({ logOutNow: true });
    }
  }

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <UserLayout logoutCallBack={this.getLogoutResponse.bind(this)}>
        <div className={classes.godContainer}>
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />
          {this.showLoader()}

          {this.redirectToDashboard()}
          <Paper>
            <Grid xl justify="center" alignItems="center" item>
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChange}
              >
                <Tab label={LanguageManager.Strings.ACTIVE_TRIP_LABEL} />
                <Tab label={LanguageManager.Strings.PAST_TRIP_LABEL} />
              </Tabs>
            </Grid>
          </Paper>

          {value === 0 && (
            <TabContainer>
              {this.state.holderData.length === 0 ? (
                <NoReq />
              ) : (
                this.state.holderData.map((val, index) => (
                  <Fade in={true}>
                    <Activetrips
                      openSpecificDialog={id =>
                        this.setState({
                          bookingId: id,
                          isSpecificDialog: !this.state.isSpecificDialog
                        })
                      }
                      data={val}
                    />
                  </Fade>
                ))
              )}
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              {this.state.pastTripsData.length === 0 ? (
                <NoReq message={LanguageManager.Strings.NO_JOURNEYS_MESSAGE} />
              ) : (
                this.state.pastTripsData.map((val, index) => (
                  <Activetrips
                    openSpecificDialog={id =>
                      this.setState({
                        bookingId: id,
                        isSpecificDialog: !this.state.isSpecificDialog
                      })
                    }
                    data={val}
                  />
                ))
              )}
            </TabContainer>
          )}
          <CustomDialog
            className={classes.DialogWidth}
            visible={this.state.isSpecificDialog}
            onOutsideTouch={() => {
              this.setState({
                isSpecificDialog: !this.state.isSpecificDialog
              });
            }}
          >
            <ConfirmBooking bookingId={this.state.bookingId} />
          </CustomDialog>
        </div>
      </UserLayout>
    );
  }
}

export default withStyles(ManageTripStyles)(Usertrips);
