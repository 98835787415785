import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Modal } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import { LOADER } from "../../values/Icons";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",

    bordeRadius: 10,

    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "50px",
    paddingRight: "50px"
  },
  loaderTitle: {
    fontSize: 16,
    color: "white",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  }
});
const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    borderRadius: "8px",
    transform: `translate(-${top}%, -${left}%)`
  };
};

const CustomLoader = props => {
  const { classes, showLoader } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.showLoader ? props.showLoader : false}
      disableAutoFocus={true}
      onClose={() => {
        //  this.props.handleModalTouchOutisde();
      }}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <CircularProgress className={classes.progress} color="secondary" />
        {/* <img src={LOADER} /> */}
        <Typography className={classes.loaderTitle}>
          {props.loadingText ? props.loadingText : "Please Wait..."}
        </Typography>
      </div>
    </Modal>
  );
};

CustomLoader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomLoader);
