import { GOOGLE_API_KEY } from "../../constants/AppConstants";

// export const TAXI_IMAGE_URL = "http://almonacib.net/Apporiov19/public/";

export const TAXI_IMAGE_URL = "";

// "http://delhitrial.apporiotaxi.com/Apporiov19/public/";

// export const TAXI_IMAGE_URL = "http://app.apporiotaxi.com/v18/public/";

// export const USERAPP_IMAGE_URL = "http://app.apporiotaxi.com/v18/public/";

export const USERAPP_IMAGE_URL =
  //  "http://eltaximx.com/main/public/";

  // "https://www.varada.club/main/public/";

  // "https://exicabs.com/main/public/";

  "http://upesirides.com/main/public/";

// "http://delhi.apporiotaxi.com/Apporiov19/public/";

// "https://iraitemx.com/main/public/";

// "https://irideph.com/main/public/";

// "https://gogolao.com/main/public/";

// "https://transup.co.ls/transup/public/";

// "https://carpop.com.br/main-taxi/public/";

// "https://www.maya4women.com/maya/public/";

// "https://taxi-rider.co.uk/ginilabs/public/";

// "https://smsfrica.com/main/public/";

// "https://easytaxinepal.com/nepal-taxi/public/";

// "https://ogcabs.com/taxi/public/";

// "http://202.51.74.228/nepal-taxi/public/";

// export const USERAPP_IMAGE_URL = "http://almonacib.net/Apporiov19/public/";

const VAIBHAV_SANDBOX_BASE_URL = " https://adminpanel.getsandbox.com/";

//const TAXI_BASE_URL = "http://app.apporiotaxi.com/Development/public/api/";http://dev.apporiotaxi.com/v11/public/

// const TAXI_BASE_URL = "http://app.apporiotaxi.com/v18/public/api/";

// const TAXI_BASE_URL = "https://exicabs.com/main/public/api/";

const TAXI_BASE_URL = "https://www.rolotech.org/mainv21/public/api/";

//const TAXI_BASE_URL = "https://upesirides.com/main/public/api/";

// const TAXI_BASE_URL = "http://eltaximx.com/main/public/api/";

// const TAXI_BASE_URL = "https://iraitemx.com/main/public/api/";

// const TAXI_BASE_URL = "https://www.varada.club/main/public/api/";

// const TAXI_BASE_URL = "https://gogolao.com/main/public/api/";

// const TAXI_BASE_URL = "https://www.transup.co.ls/transup/public/api/";

// const TAXI_BASE_URL = "https://carpop.com.br/main-taxi/public/api/";

// const TAXI_BASE_URL = "https://taxi-rider.co.uk/ginilabs/public/api/";

// const TAXI_BASE_URL = "https://smsfrica.com/main/public/api/";

// const TAXI_BASE_URL = "http://skylarcabs.com/main-cabs/public/api/";

// const TAXI_BASE_URL = "https://ogcabs.com/taxi/public/api/";

// const TAXI_BASE_URL = "https://easytaxinepal.com/nepal-taxi/public/api/";

// const TAXI_BASE_URL = "http://taxitourist.net/main/public/api/";

// const TAXI_BASE_URL = "https://www.maya4women.com/maya/public/api/";

// const TAXI_BASE_URL = "http://202.51.74.228/nepal-taxi/public/api/";

// const TAXI_BASE_URL = "http://almonacib.net/Apporiov19/public/api/";

// const TAXI_API_URL = TAXI_BASE_URL + "Production/public/api/";

// export const TAXI_IMAGE_BASE = TAXI_BASE_URL + "Production/public/";

const SANDBOX_BASE_URL = "http://apporiotaxisite.getsandbox.com/"; //'http://apporio-taxi-site.getsandbox.com/'

export const CONFIG_API = SANDBOX_BASE_URL + "config";

export const GOOGLE_AUTO_COMPLETE_API = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${GOOGLE_API_KEY}`;

export const GOOGLE_PLACE_DETAILS_API = `https://maps.googleapis.com/maps/api/place/details/json?key=${GOOGLE_API_KEY}`;

const OPEN_STREET_BASE_URL = "https://nominatim.openstreetmap.org/";

export const OPEN_STREET_PLACE_SEARCH =
  OPEN_STREET_BASE_URL + "search.php?polygon_geojson=1&format=json&q=";

export const OPEN_STREET_POLYGON_SERIES =
  OPEN_STREET_BASE_URL + "details.php?polygon_geojson=1&format=json&place_id=";

export const GOOGLE_STATIC_MAP = `https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=600x300&key=${GOOGLE_API_KEY}&center=`;
export const NEW_SANDBOX_BASE = "http://apporio-admin-panel.getsandbox.com/";

const AKSHAY_BASE_URL = "https://red-star-4507.getsandbox.com/";

export const DRIVER_APIS = {
  DRIVER_APP_FEATURES_API_URL: AKSHAY_BASE_URL + "home/features",
  USER_APP_IMAGES_API: AKSHAY_BASE_URL + "userImages",
  DRIVER_APP_REQUIRED_DOCS_URL: AKSHAY_BASE_URL + "tab_content"
};

export const ADMIN_SANDBOX_API = {
  DRIVER_LISTING: VAIBHAV_SANDBOX_BASE_URL + "edit_driver_home"
};

export const APPORIO_TAXI_APIS = {
  MOCK_USER_API: "https://apporiotaxi.getsandbox.com/dashboardConfig",

  USER_GETESTIMATE: TAXI_BASE_URL + "user/checkEstimate",
  USER_CARS_NO_LOGIN: TAXI_BASE_URL + "user/carsWithoutLogin",
  USER_SAVECARD: TAXI_BASE_URL + "user/save-card",

  USER_SOCIALOGIN: TAXI_BASE_URL + "user/socialsignin",
  USER_SOCIALREGISTER: TAXI_BASE_URL + "user/socialsingup",
  USER_GETESTIMATE: TAXI_BASE_URL + "user/checkEstimate",
  USER_CARS_NO_LOGIN: TAXI_BASE_URL + "user/carsWithoutLogin",

  DRIVER_GETCONFIG: TAXI_BASE_URL + "driver/configuration",
  USER_LOGIN: TAXI_BASE_URL + "user/login",
  USER_SPECIFIC_DETAIL: TAXI_BASE_URL + "user/login",
  USER_REGISTER: TAXI_BASE_URL + "user/signup",

  USER_FAV_DRIVERS: TAXI_BASE_URL + "user/favourite/drivers",
  USER_OUTSTATION_DETAILS: TAXI_BASE_URL + "user/outstation-details",

  GET_OTP: TAXI_BASE_URL + "user/otp",
  USER_FORGOT_PASSWORD: TAXI_BASE_URL + "user/forgotpassword",
  PAYMENT_OPTIONS: TAXI_BASE_URL + "user/payment-option",
  CAR_TYPES: TAXI_BASE_URL + "user/cars",
  NEAREST_DRIVERS: TAXI_BASE_URL + "user/driver",
  CHANGE_PAYMENT_OPTION: TAXI_BASE_URL + "user/payment",

  CHANGE_PAYMENT_OPTION: TAXI_BASE_URL + "user/payment",

  DRIVER_ADDNEWCAR: TAXI_BASE_URL + "driver/addvehicle",
  DRIVER_ADD_EXISTING_CAR: TAXI_BASE_URL + "driver/vehicleRequest",

  DRIVER_DETAILS: TAXI_BASE_URL + "driver/details",

  DRIVER_GET_DOCSLIST: TAXI_BASE_URL + "driver/documentlist",

  DRIVER_CARDOCUPLOAD: TAXI_BASE_URL + "driver/addvehicledocument",
  DRIVER_DOCUPLOAD: TAXI_BASE_URL + "driver/adddocument",
  DRIVER_GETCARTYPES: TAXI_BASE_URL + "driver/vehicleconfiguration",
  DRIVER_LOGIN: TAXI_BASE_URL + "driver/login",
  DRIVER_SIGNUP: TAXI_BASE_URL + "driver/firtstep",

  ACTIVE_TRIPS: TAXI_BASE_URL + "driver/booking/history/active",
  PAST_TRIPS: TAXI_BASE_URL + "driver/booking/history/past",
  SCH_TRIPS: TAXI_BASE_URL + "driver/booking/history/schedule",
  HISTORY_DETAIL: TAXI_BASE_URL + "driver/booking/history/detail",
  CHECKOUT_API: TAXI_BASE_URL + "user/checkout",
  ADD_FAVOURITE_LOCATION: TAXI_BASE_URL + "user/favourite/location",
  USER_FAVOURITE_LOCATION_LIST: TAXI_BASE_URL + "user/viewfavourite",
  BOOKING_CONFIRM: TAXI_BASE_URL + "user/confirm",
  APPLY_COUPON: TAXI_BASE_URL + "user/checkout/apply-promo",
  SELECT_FAV_DRIVER: TAXI_BASE_URL + "user/favourite/drivers",
  USER_PAST_TRIP: TAXI_BASE_URL + "user/booking/history",
  USER_ACTIVE_TRIP_API: TAXI_BASE_URL + "user/booking/history/active",
  USER_AUTO_CANCEL_API: TAXI_BASE_URL + "user/booking/autocancel",
  USER_SPECIFIC_DETAILS: TAXI_BASE_URL + "user/booking/history/detail",
  USER_TRANSACTION_LOG: TAXI_BASE_URL + "user/wallet/transaction", //api/user/wallet/transaction
  USER_DETAILS: TAXI_BASE_URL + "user/UserDetail",
  USER_PROFILE_EDIT: TAXI_BASE_URL + "user/edit-profile",
  USER_HOME_LIST: TAXI_BASE_URL + "user/website/homeScreen",
  MANAGE_VEHICLES: TAXI_BASE_URL + "driver/allVehicles",
  FETCH_MODELS: TAXI_BASE_URL + "driver/vehiclemodel",
  MANAGE_VEHICLES: TAXI_BASE_URL + "driver/allVehicles",
  FETCH_MODELS: TAXI_BASE_URL + "driver/vehiclemodel",
  DRIVER_EARNINGS: TAXI_BASE_URL + "driver/earnings",
  DRIVER_ADDRESS: TAXI_BASE_URL + "driver/homeaddress",
  DRIVER_DETAILS: TAXI_BASE_URL + "driver/details",
  ACC_TYPE: TAXI_BASE_URL + "driver/account-types",
  EDIT_BANK: TAXI_BASE_URL + "driver/bankdetails",
  EDIT_PROFILE: TAXI_BASE_URL + "driver/edit-profile",
  ADD_ADDRESS: TAXI_BASE_URL + "driver/add/homeaddress",
  DELETE_DRIVER_ADDRESS: TAXI_BASE_URL + "driver/delete/homelocation",
  SELECT_DRIVER_HOME_ADDRESS: TAXI_BASE_URL + "driver/select/homelocation",
  DRIVER_ADDRESS_ACTIVE_DEACTIVE:
    TAXI_BASE_URL + "driver/active/deactive/homelocation",
  DRIVER_PERSONAL_DOCUMENT: TAXI_BASE_URL + "driver/personal/documentlist",
  ADD_ADDRESS: TAXI_BASE_URL + "driver/add/homeaddress",
  ADD_DRIVER_DOCUMENT: TAXI_BASE_URL + "driver/adddocument",
  DRIVER_WALLET: TAXI_BASE_URL + "driver/wallet/transaction",
  DRIVER_CARDS: TAXI_BASE_URL + "driver/cards",
  CHANGE_PASSWORD: TAXI_BASE_URL + "driver/changepassword",
  USER_CONFIG: TAXI_BASE_URL + "user/configuration",
  SAVE_DRIVER_CARDS: TAXI_BASE_URL + "driver/savecards",
  CHANGE_PASSWORD: TAXI_BASE_URL + "driver/changepassword",
  DRIVER_OTP_GEN: TAXI_BASE_URL + "driver/otp",
  DRIVER_FORGOT_PASSWORD: TAXI_BASE_URL + "driver/forgotpassword",
  USER_CARDS: TAXI_BASE_URL + "user/cards",
  USER_ADDTOWALLET: TAXI_BASE_URL + "user/wallet/addMoney",
  DRIVER_ADDTOWALLET: TAXI_BASE_URL + "driver/wallet/addMoney",
  CHANGE_VEHICLE: TAXI_BASE_URL + "driver/changeVehicle",
  DRIVER_CMSDATA: TAXI_BASE_URL + "driver/cms/pages",
  USER_CMSDATA: TAXI_BASE_URL + "user/cms/pages",

  DRIVER_HOME_LISTING: TAXI_BASE_URL + "driver/website/homeScreen",
  BOOKING_STATUS: TAXI_BASE_URL + "bookingStatus",
  DASHBOARD_CAR_DETAILS: TAXI_BASE_URL + "user/website/service",
  BOOKING_DETAILS: TAXI_BASE_URL + "user/booking/details"
};

//executed ==>: http://dev.apporiotaxi.com/v11/public/api/driver/addvehicledocument
